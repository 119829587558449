import {FetchAPI} from '@renedelangel/helpers';
import {urlServidor} from '../../helpers';

export const ADD_SOLICITUDSEGURO = `
mutation addSolSegFian($input: InputAddSolSegFian){
    addSolSegFian(input: $input){
        solSegFianID
        usuarioBitID
        empresaID
        solicitud
        ramoSeguroID
        fechaSolicitud
        solicitante
        puesto
        departamentoID
        observaciones
        nomAutoriza
        estatus
        ramoSeguro
        folioInterno
        horasInvertidas
        folioControlPago
        subRamoID
        subPolizaID
        subRamo
        codigoMoneda
    }
}

`;

export const UPDATE_SOLICITUDSEGURO = `
mutation updateSolSegFian($input: InputUpdateSolSegFian){
    updateSolSegFian(input: $input){
        solSegFianID
        usuarioBitID
        empresaID
        solicitud
        ramoSeguroID
        fechaSolicitud
        solicitante
        puesto
        departamentoID
        observaciones
        nomAutoriza
        estatus
        ramoSeguro
        folioInterno
        horasInvertidas
        folioControlPago
        subRamoID
        subPolizaID
        subRamo
        codigoMoneda
    }

}
`;

export const ESTATUS_INVITADO = `
mutation estatusInvitado($input: InputEstatusInvitadoSolSegFian){
    estatusInvitado(input: $input){
        solSegFianID
        usuarioBitID
        estatus
        estatusInvitado
    }

}
`;

export const DELETE_SOLICITUDSEGURO = `
mutation deleteSolSegFian($input: InputDeleteSolSegFian){
    deleteSolSegFian(input: $input)
}
`;

export const APLICAR_SOLICITUDSEGURO = `
mutation aplicarPagoSolSegFian($input: InputAplicarSolSegFian){
    aplicarPagoSolSegFian(input: $input)
}
`;

export const APLICARSEGURO_SOLICITUDSEGURO = `
mutation asegurarActivosEnPoliza($input: InputAplicarSolSegFian){
    asegurarActivosEnPoliza(input: $input)
}
`;

export const ACTUALIZARPARIDAD_SOLICITUDSEGURO = `
mutation actualizarParidadSolSegFian($input: InputActualizarParidadSolSegFian){
    actualizarParidadSolSegFian(input: $input)
}
`;

export const addSolicitudSeguro = async (variables, token) =>
{
    try
    {let {addSolSegFian} =await FetchAPI.graphql({
        token,
        variables,
        url: urlServidor,
        query: ADD_SOLICITUDSEGURO
    });

    return addSolSegFian;
    } catch({message}) {throw new Error(message);}
};

export const updateSolSegFian = async (variables, token) => {

    try {
        let{updateSolSegFian} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_SOLICITUDSEGURO
        });
        return updateSolSegFian;
    } catch ({message}) {throw new Error(message);}
};

export const estatusInvitado = async (variables, token) => {

    try {
        let{estatusInvitado} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ESTATUS_INVITADO
        });
        return estatusInvitado;
    } catch ({message}) {throw new Error(message);}
};

export const deleteSolicitudSeguro = async (input, token) => {

    try {
        let {deleteSolSegFian} = await FetchAPI.graphql({
            token,
            variables: {input},
            url: urlServidor,
            query: DELETE_SOLICITUDSEGURO
        });
        return deleteSolSegFian;
    } catch ({message}) {throw new Error(message);}
};

export const aplicarPagoSolicitudSeguro = async (input, token) => {
    try {
        let { aplicarPagoSolSegFian } = await FetchAPI.graphql({
            token,
            variables: { input },
            url: urlServidor,
            query: APLICAR_SOLICITUDSEGURO
        });

        return aplicarPagoSolSegFian;

    } catch ({ message }) { throw new Error(message); }
};


export const asegurarActivosEnPoliza = async (input, token) => {
    try {
        let { asegurarActivosEnPoliza } = await FetchAPI.graphql({
            token,
            variables: { input },
            url: urlServidor,
            query: APLICARSEGURO_SOLICITUDSEGURO
        });

        return asegurarActivosEnPoliza;

    } catch ({ message }) { throw new Error(message); }
};

export const actualizarParidadSolicitudSeguro = async (input, token) => {
    try {
        let { actualizarParidadSolSegFian } = await FetchAPI.graphql({
            token,
            variables: { input },
            url: urlServidor,
            query: ACTUALIZARPARIDAD_SOLICITUDSEGURO
        });

        return actualizarParidadSolSegFian;

    } catch ({ message }) { throw new Error(message); }
};